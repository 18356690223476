import React, { useEffect, useState, useGlobal, useMemo } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Switch,
  message,
  DatePicker,
  TimePicker,
  InputNumber
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { SpeakerPicker } from '../components';
import { GroupPicker } from '../components';
import { ChromePicker } from 'react-color';
import api from '../service/index';
import { Link } from 'react-router-dom';
import rgbHex from 'rgb-hex';
import { useCallback, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
Quill.register('modules/imageResize', ImageResize);

const SessionsItemDetail = (props) => {
  const quillRef = useRef(null);

  let params = useParams();
  let history = useHistory();
  let photoAlbum = params.id !== 'add' ? params.id : false;
  let id = params.subid !== 'add' ? params.subid : false;

  let [modules] = useGlobal('modules');
  let [languagesCms] = useGlobal('languagesCms');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let newRecord = {
    name: '',
    description: '',
    speakersNames: '',
    backgroundColor: '',
    venueLatitude: '',
    venueLongitude: '',
    venueName: '',
    speaker: [],
    order: 0,
    active: '1',
    startDate: new Date().toString(),
    endDate: new Date().toString(),
    startTime: new Date().toString(),
    endTime: new Date().toString(),
    categoryId: params.id,
    slides: [],
  };

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(true);

  let [validationCheck, setValidationCheck] = useState(false);

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  let get = async () => {
    if (id) {
      await api
        .get('/rest/sessions/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  let validate = useCallback(() => {
    let errors = {};

    if (data.name == null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED;
    if (data.startDate == null || data.startDate.length === 0)
      errors.startDate = languagesCms.ENFORCED;
    if (data.endDate == null || data.endDate.length === 0)
      errors.endDate = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/sessions/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/sessions/detail/' + photoAlbum);
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/sessions', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/sessions/detail/' + photoAlbum);
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  let imageHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const quill = quillRef.current.getEditor();
      const file = input.files[0];
      const formData = new FormData();

      formData.append('files_0', file);

      const range = quill.getSelection(true);
      quill.insertEmbed(
        range.index,
        'image',
        `${window.location.origin}/images/loading.gif`,
      );
      quill.setSelection(range.index + 1);

      let url = await api
        .post('/api/upload', formData, {
          headers: { ContenType: 'multipart/form-data' },
        })
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            return result[0].url;
          }
        });
      quill.deleteText(range.index, 1);
      quill.insertEmbed(range.index, 'image', url);
    };
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'size',
    'video',
    'align',
    'background',
    'direction',
    'code-block',
    'code',
    'script',
    'clean',
  ];
  const quillmodules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          ['link', 'image', 'video'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { align: [] },
            { direction: 'rtl' },
          ],
          ['clean'],
          ['code-block'],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ color: [] }, { background: [] }],
          [{ script: 'sub' }, { script: 'super' }, 'formula'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
    }),
    [],
  );

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to={'/sessions/detail/' + photoAlbum}>
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.TITLE}
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.GROUP}>
                  <GroupPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="groups"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item
                  label={languagesCms.EXPLANATION}
                  help={errors.description}
                  validateStatus={errors.description ? 'error' : 'success'}
                >
                  <ReactQuill
                    ref={quillRef}
                    modules={quillmodules}
                    formats={formats}
                    name="description"
                    theme="snow"
                    value={data.description ? data.description : ''}
                    onChange={(description) =>
                      setData({ ...data, description: description })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.STATUS}
                  required
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.active ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, active: v })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">

            <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item
                  label={languagesCms.ORDER}
                  help={errors.order}
                  validateStatus={errors.order ? 'error' : 'success'}
                >
                  <InputNumber
                    name="order"
                    value={data.order}
                    onChange={(v) => setData({ ...data, order: v })}
                  />
                </Form.Item>
              </Col>
              </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.BACKGROUND_COLOR}
                  help={errors.backgroundColor}
                  validateStatus={errors.backgroundColor ? 'error' : 'success'}
                >
                  <ChromePicker
                    disableAlpha
                    color={data.backgroundColor}
                    onChange={(color) =>
                      setData({
                        ...data,
                        backgroundColor:
                          '#' + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b),
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.SPEAKERS}
                  help={errors.speaker}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <SpeakerPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="speaker"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.PLACE}
                >
                  <Input
                    name="place"
                    value={data.place}
                    onChange={(e) => setData({ ...data, place: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.SALON}
                >
                  <Input
                    name="salon"
                    value={data.salon}
                    onChange={(e) => setData({ ...data, salon: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.START_DATE}
                  help={errors.startDate}
                  validateStatus={errors.startDate ? 'error' : 'success'}
                >
                  <DatePicker
                    defaultValue={() => moment(data.startDate)}
                    onChange={(v) => setData({ ...data, startDate: v })}
                    format="YYYY-MM-DD"
                    showTime={true}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.END_DATE}
                  help={errors.endDate}
                  validateStatus={errors.endDate ? 'error' : 'success'}
                >
                  <DatePicker
                    defaultValue={() => moment(data.endDate)}
                    onChange={(v) => setData({ ...data, endDate: v })}
                    format="YYYY-MM-DD"
                    showTime={true}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.START_TIME}
                  help={errors.startTime}
                  validateStatus={errors.startTime ? 'error' : 'success'}
                >
                  <TimePicker
                    defaultValue={() => moment(data.startTime)}
                    onChange={(v) => setData({ ...data, startTime: v })}
                    showTime={true}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.END_TIME}
                  help={errors.endTime}
                  validateStatus={errors.endTime ? 'error' : 'success'}
                >
                  <TimePicker
                    defaultValue={() => moment(data.endTime)}
                    onChange={(v) => setData({ ...data, endTime: v })}
                    showTime={true}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default SessionsItemDetail;
