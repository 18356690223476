import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { useGlobal } from "reactn";

const { Sider } = Layout;

const SideMenu = ({ testFunc }) => {
    const [collapsed, setCollapsed] = useGlobal('collapsed');
    let [isSmall] = useGlobal('isSmall');
    let [modules] = useGlobal("modules");
    //let [AttendeeGroup, setAttendeeGroup] = useState([]);
    let logo = useGlobal("logo")[0];
    let cpName = useGlobal("login_setting")[0];

    var url = {
        backgroundImage: `url(${logo})`,
        backgroundPosition:'center',
        backgroundSize:'contain'
    };
    function tabTitle(titleName) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title= cpName + " - " + titleName;
    }

    const getMenu = () => {
      if(modules){
                 return modules.map(item => {
                    if (item.type === "infos") {
                        item.type = item.refId;
                    }
                    return (<Menu.Item key={item.type} onClick={() => tabTitle(item.name) }>
                        <Link to={"/" + item.type}>
                            <img alt="" src={item.icon} width="40" />
                            <span>{item.name}</span>
                        </Link>
                    </Menu.Item>);
                })       
      }
    }

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)} theme="light" width={isSmall ? '150' : '260'}>
            <div className="logo-wrap">
                <div className="logo" style={url}></div>
            </div>
            <Menu theme="light" defaultSelectedKeys={['1']} mode="inline">
                {getMenu()}
            </Menu>
        </Sider>
    );
};


export default SideMenu;